<template>
  <v-form
    class="multi-col-validation"
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="formTransaction.penyelenggara"
          :rules="[(v) => !!v || 'Mohon isi penyelenggara']"
          label="ID Penyelenggara"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <!-- <v-text-field
          v-model="formData.idPinjaman"
          :rules="[(v) => !!v || 'Mohon isi ID pinjaman']"
          label="ID Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field> -->
        <v-autocomplete
          v-model="formTransaction.idPinjaman"
          :items="statesLoan"
          item-text="id_pinjaman"
          item-value="id"
          label="ID Pinjaman"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <!-- <v-select
          v-model="formData.borrower"
          :items="borrowerOptions"
          item-text="nama_pengguna"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih borrower']"
          label="Borrower"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select> -->
        <v-autocomplete
          v-model="formTransaction.borrower"
          :items="statesBorrower"
          item-text="id_borrower"
          item-value="id"
          label="Borrower"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <!-- <v-select
          v-model="formData.lender"
          :items="lenderOptions"
          item-text="nama_pengguna"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih lender']"
          label="Lender"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select> -->
        <v-autocomplete
          v-model="formTransaction.lender"
          :items="states"
          item-text="id_lender"
          item-value="id"
          label="Lender"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formTransaction.idTransaksi"
          :rules="[(v) => !!v || 'Mohon isi ID transaksi']"
          label="ID Transaksi"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="formTransaction.nomorPerjanjianBorrower"
          :rules="[(v) => !!v || 'Mohon isi no Perjanjian Borrower']"
          label="nomorPerjanjianBorrower"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <!-- <v-text-field
          v-model="formData.satuanSukuBungaPinjaman"
          :rules="[(v) => !!v || 'Mohon isi satuan suku bunga pinjaman']"
          label="Satuan Suku Bunga Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field> -->
        <v-select
          v-model="formTransaction.satuanSukuBungaPinjaman"
          :items="satuanSukuBungaPinjamanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi satuan suku bunga pinjaman']"
          label="Satuan Suku Bunga Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formTransaction.nilaiPendanaan"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi nilai pendanaan']"
          label="Nilai Pendanaan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formTransaction.nilaiAngsuran"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi nilai angsuran']"
          label="Nilai Angsuran"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formTransaction.objekPinjaman"
          :rules="[(v) => !!v || 'Mohon isi objek pinjaman']"
          label="Objek Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formTransaction.sukuBungaPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi suku bunga pinjaman']"
          label="Suku Bunga Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPerjanjianBorrowerMenu"
          v-model="tanggalPerjanjianBorrowerMenu"
          :close-on-content-click="false"
          :return-value.sync="formTransaction.tanggalPerjanjianBorrower"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formTransaction.tanggalPerjanjianBorrower"
              label="Tanggal Perjanjian Borrower"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formTransaction.tanggalPerjanjianBorrower"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPerjanjianBorrowerMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPerjanjianBorrowerMenu.save(
                  formTransaction.tanggalPerjanjianBorrower
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12">
        <v-menu
          ref="tanggalJatuhTempoMenu"
          v-model="tanggalJatuhTempoMenu"
          :close-on-content-click="false"
          :return-value.sync="formTransaction.tanggalJatuhTempo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formTransaction.tanggalJatuhTempo"
              label="Tanggal Jatuh Tempo"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formTransaction.tanggalJatuhTempo"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="tanggalJatuhTempoMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalJatuhTempoMenu.save(
                  formTransaction.tanggalJatuhTempo
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formTransaction.jenisPembayaran"
          :items="jenisPembayaranOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih jenis pembayaran']"
          label="Jenis Pembayaran"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formTransaction.frekuensiPembayaran"
          :items="frekuensiPembayaranOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih frekuensi pembayaran']"
          label="Frekuensi Pembayaran"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formTransaction.satuanJangkaWaktuPinjaman"
          :rules="[(v) => !!v || 'Mohon isi satuan jangka waktu pinjaman']"
          label="Satuan Jangka Waktu Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formTransaction.frekuensiPendanaan"
          :items="frekuensiPembayaranOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi frekuensi pendanaan']"
          label="Frekuensi Pendanaann"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
        <!-- <v-text-field
          v-model="formData.frekuensiPendanaan"
          :rules="[(v) => !!v || 'Mohon isi frekuensi pendanaan']"
          label="Frekuensi Pendanaann"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field> -->
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formTransaction.noEaTransaksi"
          :rules="[(v) => !!v || 'Mohon isi no ea transaksi']"
          label="No EA Transaksi"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPendanaanMenu"
          v-model="tanggalPendanaanMenu"
          :close-on-content-click="false"
          :return-value.sync="formTransaction.tanggalPendanaan"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formTransaction.tanggalPendanaan"
              label="Tanggal Pendanaan"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formTransaction.tanggalPendanaan"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="tanggalPendanaanMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPendanaanMenu.save(
                  formTransaction.tanggalPendanaan
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPenyaluranDanaMenu"
          v-model="tanggalPenyaluranDanaMenu"
          :close-on-content-click="false"
          :return-value.sync="formTransaction.tanggalPenyaluranDana"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formTransaction.tanggalPenyaluranDana"
              label="Tanggal Penyaluran Dana"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formTransaction.tanggalPenyaluranDana"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPenyaluranDanaMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPenyaluranDanaMenu.save(
                  formTransaction.tanggalPenyaluranDana
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formTransaction.jangkaWaktuPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi jangka waktu pinjaman']"
          label="Jangka Waktu Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import store from "../../store/index.js";

export default {
  props: {
    formTransaction: Object,
  },
  data: () => ({
    valid: true,
    loading: false,
    tanggalPerjanjianBorrowerMenu: false,
    tanggalJatuhTempoMenu: false,
    tanggalPendanaanMenu: false,
    tanggalPenyaluranDanaMenu: false,
    jenisPembayaranOptions: [],
    frekuensiPembayaranOptions: [],
    isReportOptions: [
      {
        label: "Ya",
        id: 1,
      },
      {
        label: "Tidak",
        id: 0,
      },
    ],
    satuanSukuBungaPinjamanOptions: [
      {
        label: "Hari",
        id: 1,
      },
      {
        label: "Minggu",
        id: 2,
      },
      {
        label: "Bulan",
        id: 3,
      },
      {
        label: "Tahun",
        id: 4,
      },
    ],
    states: [],
    statesBorrower: [],
    statesLoan: [],
  }),

  async mounted() {
    this.formData = this.formTransaction;
    this.loading = "primary";

    this.getPaymentType();
    this.getPaymentFrequencies();

    await store.dispatch("office/fetchAllBorrower").then((r) => {
      if (r) this.loading = false;
    });

    await store.dispatch("office/fetchAllLoan").then((r) => {
      if (r) this.loading = false;
    });

    await store.dispatch("office/fetchAllLender").then((r) => {
      if (r) this.loading = false;
    });

    this.states = store.state.office.allLender;
    this.statesBorrower = store.state.office.allBorrower;
    this.statesLoan = store.state.office.allLoan;
  },

  methods: {
    async getPaymentType() {
      await store.dispatch("options/fetchPaymentTypes");
      this.jenisPembayaranOptions = store.state.options.paymentTypes;
    },
    async getPaymentFrequencies() {
      await store.dispatch("options/fetchPaymentFrequencies");
      this.frekuensiPembayaranOptions = store.state.options.paymentFrequencies;
    },
  },
};
</script>
