<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Edit Data Transaksi'" />
    <!-- <stepper-transaction :steps="steps" /> -->

    <div v-if="!this.$route.params.status">
      <!-- <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
      ></VueFileAgent>
      <button-form
        :submit="submitTransaction"
        :buttonText="'Upload'"
        class="pt-8"
      />

      <v-divider class="my-6" /> -->

      <h4 class="pb-4">Form Input</h4>
      <form-transaction :formTransaction="formNewTransaction" />
      <button-form
        :submit="submitTransaction"
        :loading="loading"
        class="pt-8"
      />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperTransaction from "../../components/Stepper/StepperTransaction.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormTransaction from "../../components/Form/FormTransactionEdit.vue";
import FormPayment from "../../components/Form/FormPayment.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    StepperTransaction,
    ButtonForm,
    FormTransaction,
    FormPayment,
    PopupSuccess,
    PopupError,
  },

  data() {
    return {
      steps: 1,
      uploadedFile: "",
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      formNewTransaction: {
        penyelenggara: "",
        idPinjaman: "",
        borrower: null,
        lender: null,
        idTransaksi: "",
        satuanSukuBungaPinjaman: "",
        nomorPerjanjianBorrower: "",
        nilaiPendanaan: "",
        nilaiAngsuran: "",
        objekPinjaman: "",
        sukuBungaPinjaman: "",
        tanggalPerjanjianBorrower: "",
        tanggalJatuhTempo: "",
        jenisPembayaran: null,
        frekuensiPembayaran: null,
        satuanJangkaWaktuPinjaman: "",
        frekuensiPendanaan: "",
        noEaTransaksi: "",
        tanggalPendanaan: "",
        tanggalPenyaluranDana: "",
        jangkaWaktuPinjaman: "",
        isReport: null,
      },
    };
  },

  async mounted() {
    if (this.$route.params.status) {
      this.steps = 2;
    } else {
      this.steps = 1;
    }
    await store.dispatch("office/fetchTransaction", this.$route.params.id);
    this.formNewTransaction = {
      penyelenggara: store.state.office.currentTransaction.id_penyelenggara,
      idPinjaman: store.state.office.currentTransaction.id_pinjaman,
      borrower: store.state.office.currentTransaction.id_borrower,
      lender: store.state.office.currentTransaction.id_lender,
      idTransaksi: store.state.office.currentTransaction.id_transaksi,
      satuanSukuBungaPinjaman:
        store.state.office.currentTransaction.satuan_suku_bunga_pinjaman,
      nomorPerjanjianBorrower:
        store.state.office.currentTransaction.no_perjanjian_borrower,
      nilaiPendanaan: store.state.office.currentTransaction.nilai_pendanaan,
      nilaiAngsuran: store.state.office.currentTransaction.nilai_angsuran,
      objekPinjaman: store.state.office.currentTransaction.objek_pinjaman,
      sukuBungaPinjaman:
        store.state.office.currentTransaction.suku_bunga_pinjaman,
      tanggalPerjanjianBorrower: store.state.office.currentTransaction.tgl_perjanjian_borrower.substring(
        0,
        10
      ),
      tanggalJatuhTempo: store.state.office.currentTransaction.tgl_jatuh_tempo.substring(
        0,
        10
      ),
      jenisPembayaran:
        store.state.office.currentTransaction.id_jenis_pembayaran,
      frekuensiPembayaran:
        store.state.office.currentTransaction.id_frekuensi_pembayaran,
      satuanJangkaWaktuPinjaman:
        store.state.office.currentTransaction.satuan_jangka_waktu_pinjaman,
      frekuensiPendanaan:
        store.state.office.currentTransaction.frekuensi_pendanaan,
      noEaTransaksi: store.state.office.currentTransaction.no_ea_transaksi,
      tanggalPendanaan: store.state.office.currentTransaction.tgl_pendanaan.substring(
        0,
        10
      ),
      tanggalPenyaluranDana: store.state.office.currentTransaction.tgl_penyaluran_dana.substring(
        0,
        10
      ),
      jangkaWaktuPinjaman:
        store.state.office.currentTransaction.jangka_waktu_pinjaman,
    };
  },

  updated() {
    if (this.$route.params.status) {
      this.steps = 2;
    } else {
      this.steps = 1;
    }
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-transaction");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    async submitTransaction() {
      this.uploadedFile = "";
      this.loading = true;

      try {
        console.log(this.formNewTransaction);
        await store
          .dispatch("office/editTransaction", {
            id: this.$route.params.id,
            data: this.formNewTransaction,
          })
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
              this.steps = 2;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          });
        // this.$router.push("/input-transaction/pembayaran");
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
  },
};
</script>
